import { render, staticRenderFns } from "./SocialShare.vue?vue&type=template&id=b2ead7f0&scoped=true"
import script from "./SocialShare.vue?vue&type=script&lang=js"
export * from "./SocialShare.vue?vue&type=script&lang=js"
import style0 from "./SocialShare.vue?vue&type=style&index=0&id=b2ead7f0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2ead7f0",
  null
  
)

export default component.exports