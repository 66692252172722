<template>
    <section class="product-grid">
        <div class="product"
            v-for="(item, index) in products"
            :key="'product' + index">
            <product-box
                :useH2="false"
                :networkId="networkId"
                :type="constants.ITEM_BOX_TYPE.GENERAL"
                :product="item"
                trackingReferrer="reletedBlog">
            </product-box>
        </div>
    </section>
</template>

<script>
import Helper from '@/services/helper';
import MixinScreenSize from '@/mixins/screenSize';
import Constants from '@/variables/constants';
import RouterPath from '@/router/path';
import ProductBox from '@/components/product/ProductBox';

export default {
    mixins: [ MixinScreenSize ],
    components: {
        ProductBox
    },
    data() {
        return {
            helper: Helper,
            constants: Constants,
            routerPath: RouterPath
        };
    },
    props: {
        products: {
            type: [ Array, Object ],
            required: true
        },
        networkId: {
            type: String,
            required: false,
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.product-grid {
    display: flex;
    flex-wrap: wrap;

    .product {
        display: block;
        padding: 0 2px 4px;
        width: 16.6666%;

        @media only screen and (max-width: $screenMedium) {
            width: 33.33333%;
        }

        @media only screen and (max-width: $screenExtraSmall) {
            width: 50%;
            padding: 0 2px 2px;
        }
    }

    .banner {
        .wrapper {
            height: 100%;
            display: flex;
            align-items: center;
            background: #333;
            overflow: hidden;
        }

        img {
            display: block;
            width: 100%;
        }

        .size-xl {
            @media only screen and (max-width: $screenLarge) {
                display: none;
            }
        }

        .size-l {
            display: none;

            @media only screen and (max-width: $screenLarge) {
                display: block;
            }

            @media only screen and (max-width: $screenMedium) {
                display: none;
            }
        }

        .size-m {
            display: none;

            @media only screen and (max-width: $screenMedium) {
                display: block;
            }

            @media only screen and (max-width: $screenExtraSmall) {
                display: none;
            }
        }

        .size-s {
            display: none;

            @media only screen and (max-width: $screenExtraSmall) {
                display: block;
            }
        }
    }
}

.no-data {
    margin: auto;
}
</style>