<template>
    <div class="social-link-button">
        <button class="twitter" @click="shareTwitter()"><font-awesome-icon :icon="['fab', 'twitter']" /> <span>TWEET</span></button>
        <button class="facebook" @click="shareFacebook()"><font-awesome-icon :icon="['fab', 'facebook-f']" /> <span>SHARE</span></button>
    </div>
</template>

<script>
export default {
    methods: {
        shareFacebook() {
            this.openPopup('http://www.facebook.com/sharer.php?u=' + window.location.href);
        },
        shareTwitter() {
            this.openPopup('http://twitter.com/share?url=' + window.location.href + '&text=' + document.title);
        },
        openPopup(url) {
            window.open(url, "ShareWindow", "height = 440, width = 700");
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.social-link-button {
    display: flex;
    align-items: center;
    

    button {
        display: flex;
        align-items: center;
        margin-right: 5px;
        border: none;
        padding: 2px 12px 2px 10px;
        font-size: 12px;
        color: #fff;
        @include borderRadius(1000px);

        &.facebook {
            background: #3b5988;
        }

        &.twitter {
            background: #517ef9;
        }

        svg {
            margin-right: 10px;
        }
    }
}
</style>